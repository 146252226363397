// alterar arquivo: public/index.html
// checar se existe código do google analytics (apagar ou substituir se for o caso)

// alterar arquivos: .firebaserc (conferir nesse se o default e target apontam para o projeto correto do firebase), firebase.json, package.json e info.txt com o nome do projeto no firebase

// color primary: public/assets/switcher/css/color2.css
// color secondary: public/assets/switcher/css/color2.css: 135

export const id_firebase = "qnwMwEmlNA8c2mNw7noj";  // Shopping


export const ids_firebase = [
    "Aa5EYFJ72u0NxZBxFnMs", // Kar 7.2 Veículos 0
    "psxC92lHXEjFMhZsYVYy", // Canever 1
    "rZvbjxmKEIFccDGsuS9w", // DsMotors 2
    "KQvV49X4MqgMQLEIsjtU", // Gramkar 3
    "uL48rfBNcbr7fnbDoyET", // M&A 5
    "SP3u3VzRQmZjk56hOrWP", // Garage77 6
    "FHS8FDsZCvVvIgT07UHL", //Maverick 7
    "SBh9mDmFmLQDSifd3trq", // Dcar motors 8
    "b5XYti4HOtwjDiQEFlvU", // Tops motors LTDA 9
    "KKcG0ci5LktAEmhg6DTK", // Verzum Veículos 10
    "oEjoQRpPsG9NhUTGhSeN", // Top Cristal 11
    "GKKwfdZRPjofYfRLYebb", // Cervatti 12
    
];

    export const href_revenda = [
        "veiculo_kar",
        "veiculo_canever",
        "veiculo_dsmotors",
        "veiculo_gramkar",
        "veiculo_mea",
        "veiculo_garage",
        "veiculo_maverick",
        "veiculo_dcar",
        "veiculo_tops",
        "veiculo_verzum",
        "veiculo_topcristal",
        "veiculo_cervatti",
    ];
    